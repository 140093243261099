import React, { useState, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  CircularProgress,
} from '@mui/material';

// Importando os logos
import VWLogo from '../assets/images/Volkswagen-Logo-PNG-Image.png';
import ChevroletLogo from '../assets/images/chevrolet-logo-png-transparent.png';
import MercedesLogo from '../assets/images/fd17ff12c372424f615edbab9167330c.png';

function Login() {
  const { setUsuario, setRole, setOrcamentoPermissoes } = useContext(AuthContext);  // Adicionar orcamentoPermissoes no contexto
  const [login, setLogin] = useState('');
  const [senha, setSenha] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    setError(null);  // Limpar erros anteriores

    try {
      const response = await fetch(
        `https://nuclearx.com.br/api_despesa/bi_login/?login=${encodeURIComponent(login)}&senha=${encodeURIComponent(senha)}`
      );

      if (response.ok) {
        const data = await response.json();

        if (Array.isArray(data) && data.length > 0) {
          const userData = data[0];
          setUsuario(userData); // Armazena o usuário no contexto
          setRole(userData.funcao);  // Armazena a função no contexto (G, B, R, S)
          
          // Armazena os códigos de orçamentos permitidos como um array
          const orcamentoPermissoes = userData.orcamento ? userData.orcamento.split(',').map(codigo => codigo.trim()) : [];
          setOrcamentoPermissoes(orcamentoPermissoes);  // Salva no contexto

          // Navegação ou lógica pós-login
          navigate('/');
        } else {
          setError('Login ou senha incorretos.');
        }
      } else {
        setError('Erro ao tentar fazer login. Tente novamente mais tarde.');
      }
    } catch (err) {
      console.error('Erro na solicitação:', err);
      setError('Erro ao tentar fazer login. Verifique sua conexão e tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          padding: 4,
          boxShadow: 3,
          borderRadius: 2,
          backgroundColor: 'white',
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Login
        </Typography>

        {/* Adicionando os logos */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <img src={VWLogo} alt="Volkswagen" style={{ width: '80px', marginRight: '10px' }} />
          <img src={ChevroletLogo} alt="Chevrolet" style={{ width: '80px', marginRight: '10px' }} />
          <img src={MercedesLogo} alt="Mercedes-Benz" style={{ width: '80px' }} />
        </Box>

        <form onSubmit={handleSubmit}>
          <TextField
            label="Login"
            variant="outlined"
            fullWidth
            margin="normal"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            required
          />
          <TextField
            label="Senha"
            variant="outlined"
            type="password"
            fullWidth
            margin="normal"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            required
          />
          {error && (
            <Typography color="error" variant="body2" align="center">
              {error}
            </Typography>
          )}
          <Box sx={{ position: 'relative', mt: 2 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={loading}
            >
              Entrar
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
        </form>
      </Box>
    </Container>
  );
}

export default Login;
