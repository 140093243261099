import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import Dashboard from './components/Dashboard';
import OrcamentoForm from './components/OrcamentoForm';
import Pendentes from './components/Pendentes';
import Aprovados from './components/Aprovados';
import RelatorioOrcamentos from './components/Relatorios';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';
import { AuthContext } from './AuthContext';
import {
  getOrcamentosPendentes,
  addOrcamento,
  aprovarOrcamento,
  getOrcamentosAprovados,
} from './services/apiService';
import '@progress/kendo-theme-default/dist/all.css';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function App() {
  const [orcamentosPendentes, setOrcamentosPendentes] = useState([]);
  const [orcamentosAprovados, setOrcamentosAprovados] = useState([]);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const { usuario, setUsuario } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrcamentosPendentes = async () => {
      try {
        const data = await getOrcamentosPendentes();
        setOrcamentosPendentes(data);
      } catch (error) {
        console.error('Erro ao buscar orçamentos pendentes', error);
      }
    };
    fetchOrcamentosPendentes();
  }, []);

  useEffect(() => {
    const fetchOrcamentosAprovados = async () => {
      try {
        const data = await getOrcamentosAprovados();
        setOrcamentosAprovados(data);
      } catch (error) {
        console.error('Erro ao buscar orçamentos aprovados', error);
      }
    };
    fetchOrcamentosAprovados();
  }, []);

  const handleAddOrcamento = async (novoOrcamento) => {
    try {
      const data = await addOrcamento(novoOrcamento);
      setOrcamentosPendentes((prev) => [...prev, data]);
    } catch (error) {
      console.error('Erro ao adicionar orçamento', error);
      setError(true);
    }
  };

  const aprovarDiretoria = async (numero, dataAprovacao) => {
    try {
      const dadosAprovacao = {
        aprovacao_diretoria: true,
        data_aprovacao_diretoria: dataAprovacao,
      };
      await aprovarOrcamento(numero, dadosAprovacao);

      setOrcamentosPendentes((prev) =>
        prev.map((orcamento) =>
          orcamento.numero === numero ? { ...orcamento, ...dadosAprovacao } : orcamento
        )
      );

      setOrcamentosPendentes((prev) => {
        const orcamentoAprovado = prev.find((orc) => orc.numero === numero);
        if (orcamentoAprovado && orcamentoAprovado.aprovacao_gerencia) {
          setOrcamentosAprovados((prevAprovados) => [...prevAprovados, orcamentoAprovado]);
          return prev.filter((orc) => orc.numero !== numero);
        }
        return prev;
      });

      setSuccess(true);
    } catch (error) {
      console.error('Erro ao aprovar orçamento pela diretoria', error);
      setError(true);
    }
  };

  const aprovarGerencia = async (numero, dataAprovacao) => {
    try {
      const dadosAprovacao = {
        aprovacao_gerencia: true,
        data_aprovacao_gerencia: dataAprovacao,
      };
      await aprovarOrcamento(numero, dadosAprovacao);

      setOrcamentosPendentes((prev) =>
        prev.map((orcamento) =>
          orcamento.numero === numero ? { ...orcamento, ...dadosAprovacao } : orcamento
        )
      );

      setOrcamentosPendentes((prev) => {
        const orcamentoAprovado = prev.find((orc) => orc.numero === numero);
        if (orcamentoAprovado && orcamentoAprovado.aprovacao_diretoria) {
          setOrcamentosAprovados((prevAprovados) => [...prevAprovados, orcamentoAprovado]);
          return prev.filter((orc) => orc.numero !== numero);
        }
        return prev;
      });

      setSuccess(true);
    } catch (error) {
      console.error('Erro ao aprovar orçamento pela gerência', error);
      setError(true);
    }
  };

  const handleLogout = () => {
    setUsuario(null);
    navigate('/login');
  };

  return (
    <div>
      {usuario ? (
        <DashboardLayout>
          <Button
            onClick={handleLogout}
            variant="contained"
            color="secondary"
            sx={{ position: 'absolute', top: 16, right: 16 }}
          >
            Logout
          </Button>
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/orcamentos"
              element={
                <PrivateRoute>
                  <OrcamentoForm addOrcamento={handleAddOrcamento} />
                </PrivateRoute>
              }
            />
            <Route
              path="/pendentes"
              element={
                <PrivateRoute>
                  <Pendentes
                    orcamentosPendentes={orcamentosPendentes}
                    aprovarDiretoria={aprovarDiretoria}
                    aprovarGerencia={aprovarGerencia}
                  />
                </PrivateRoute>
              }
            />
            <Route
              path="/aprovados"
              element={
                <PrivateRoute>
                  <Aprovados
                    orcamentosAprovados={orcamentosAprovados}
                    setOrcamentosAprovados={setOrcamentosAprovados}
                    gerarRelatorio={() => console.log('Gerar relatório')}
                  />
                </PrivateRoute>
              }
            />
            <Route
              path="/relatorios"
              element={
                <PrivateRoute>
                  <RelatorioOrcamentos orcamentos={orcamentosAprovados} />
                </PrivateRoute>
              }
            />
            <Route
              path="/login"
              element={usuario ? <Navigate to="/" /> : <Login />}
            />
          </Routes>
          {success && <div className="success-message">Operação realizada com sucesso!</div>}
          {error && <div className="error-message">Erro ao processar a operação.</div>}
        </DashboardLayout>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
