import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Modal,
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getOrcamentosAprovados, uploadAnexoNota } from '../services/apiService'; // Importar as funções de API
import dayjs from 'dayjs';

export default function Aprovados() {
  const [, setOrcamentosAprovados] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState({});
  const [modalFileURL, setModalFileURL] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [fileType, setFileType] = useState('');
  const [filteredOrcamentos, setFilteredOrcamentos] = useState([]);

  // Carregar os orçamentos aprovados ao montar o componente
  useEffect(() => {
    const fetchApprovedBudgets = async () => {
      try {
        const data = await getOrcamentosAprovados(); // Chama a API para pegar os orçamentos
        setOrcamentosAprovados(data);

        // Filtra os orçamentos que ainda não têm nota fiscal anexada e foram aprovados por **ambos** (diretoria e gerência)
        const orcamentosSemNota = data.filter(
          (orcamento) =>
            !orcamento.anexo_nota && // Não tem nota fiscal anexada
            orcamento.aprovacao_diretoria === true && // Aprovado pela diretoria
            orcamento.aprovacao_gerencia === true // Aprovado pela gerência
        );

        setFilteredOrcamentos(orcamentosSemNota);
      } catch (error) {
        console.error('Erro ao carregar orçamentos aprovados:', error);
      }
    };

    fetchApprovedBudgets();
  }, []);

  // Função para lidar com a seleção de arquivos
  const handleFileChange = (event, numeroOrcamento) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFiles((prevFiles) => ({
        ...prevFiles,
        [numeroOrcamento]: file,
      }));
      const fileURL = URL.createObjectURL(file);
      setModalFileURL(fileURL);
      setFileType(file.type);
    }
  };

  // Função para fazer o upload do anexo_nota
  const handleUploadAnexoNota = async (numeroOrcamento) => {
    const file = selectedFiles[numeroOrcamento];

    if (!file) {
      alert('Por favor, selecione um arquivo antes de enviar.');
      return;
    }

    try {
      // Faz o upload e recebe a resposta com a URL do anexo_nota
      await uploadAnexoNota(numeroOrcamento, file);

      // Atualiza a lista de orçamentos aprovados, adicionando o anexo
      setOrcamentosAprovados((prevOrcamentos) =>
        prevOrcamentos.map((orcamento) =>
          orcamento.numero === numeroOrcamento
            ? { ...orcamento, anexo_nota: true } // Atualiza o orçamento com o anexo
            : orcamento
        )
      );

      // Remove o orçamento da lista de "Aprovados Sem Nota" após o upload
      setFilteredOrcamentos((prevOrcamentos) =>
        prevOrcamentos.filter((orcamento) => orcamento.numero !== numeroOrcamento)
      );

      alert('Nota Fiscal enviada com sucesso!');
    } catch (error) {
      console.error('Erro ao enviar a Nota Fiscal:', error);
      alert('Erro ao enviar a Nota Fiscal. Verifique o console para mais detalhes.');
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalFileURL(null);
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        Orçamentos Aprovados (Sem Nota Fiscal)
      </Typography>

      <Grid container spacing={3}>
        {filteredOrcamentos.map((orcamento) => (
          <Grid item xs={12} sm={6} md={4} key={orcamento.numero}>
            <Card>
              <CardContent>
                <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                  {orcamento.titulo}
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
                  Solicitante: <span style={{ fontWeight: 'normal' }}>{orcamento.solicitante}</span>
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
                  Valor: <span style={{ fontWeight: 'normal' }}>
                    {orcamento.valor && !isNaN(orcamento.valor) ? `R$ ${parseFloat(orcamento.valor).toFixed(2)}` : 'Valor inválido'}
                  </span>
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  Data de Aprovação: <span style={{ fontWeight: 'normal' }}>
                    {orcamento.data_aprovacao_diretoria ? dayjs(orcamento.data_aprovacao_diretoria).format('DD/MM/YYYY') : 'Data não disponível'}
                  </span>
                </Typography>

                {/* Upload de arquivo */}
                <Box mt={2} display="flex" justifyContent="space-between" gap={2}>
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<UploadFileIcon />}
                  >
                    Carregar Nota Fiscal
                    <input
                      type="file"
                      accept="application/pdf,image/*"
                      onChange={(event) => handleFileChange(event, orcamento.numero)}
                      hidden
                    />
                  </Button>

                  {/* Botão para ver a nota fiscal após carregar o arquivo */}
                  {selectedFiles[orcamento.numero] && (
                    <Button
                      variant="outlined"
                      startIcon={<VisibilityIcon />}
                      onClick={() => {
                        const fileURL = URL.createObjectURL(selectedFiles[orcamento.numero]);
                        setModalFileURL(fileURL);
                        setFileType(selectedFiles[orcamento.numero].type);
                        setOpenModal(true);
                      }}
                    >
                      Ver Nota Fiscal
                    </Button>
                  )}
                </Box>

                {/* Botão para enviar o arquivo */}
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ mt: 2 }}
                  onClick={() => handleUploadAnexoNota(orcamento.numero)}
                >
                  Enviar Nota Fiscal
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Modal para visualização do arquivo */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            bgcolor: 'background.paper',
            p: 4,
          }}
        >
          {modalFileURL ? (
            fileType === 'application/pdf' ? (
              <iframe
                src={modalFileURL}
                title="Visualizador de PDF"
                width="100%"
                height="600px"
                style={{ border: 'none' }}
              ></iframe>
            ) : (
              <img
                src={modalFileURL}
                alt="Pré-visualização"
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
              />
            )
          ) : (
            <Typography variant="body1">Nenhum arquivo selecionado.</Typography>
          )}
        </Box>
      </Modal>
    </Box>
  );
}
