import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button'; // Importando o componente Button
import LogoutIcon from '@mui/icons-material/Logout'; // Importando ícone de Logout
import Grid from '@mui/material/Grid';
import { Link, useNavigate } from 'react-router-dom'; // Para redirecionar após logout
import { AuthContext } from '../AuthContext'; // Importando AuthContext

// Ícones do Material UI
import DashboardIcon from '@mui/icons-material/Dashboard';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import DescriptionIcon from '@mui/icons-material/Description'; // Ícone para Orçamentos
import AssessmentIcon from '@mui/icons-material/Assessment'; // Ícone para Relatórios


import VWLogo from '../assets/images/Volkswagen-Logo-PNG-Image.png';
import ChevroletLogo from '../assets/images/chevrolet-logo-png-transparent.png';
import MercedesLogo from '../assets/images/fd17ff12c372424f615edbab9167330c.png';

// Largura da sidebar
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })( // Mantém o estilo do main
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBarStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  backgroundColor: 'MediumBlue', // Cor de fundo da AppBar
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function DashboardLayout({ children }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate(); // Hook para redirecionar após o logout
  const { setUsuario } = React.useContext(AuthContext); // Acesso ao contexto

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    console.log("Logout clicado"); // Adicione este log
    setUsuario(null); // Definindo usuário como null
    localStorage.removeItem('user'); // Removendo dados do localStorage
    console.log("Usuário definido como null"); // Confirmação
    navigate('/login'); // Redireciona para a página de login
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBarStyled position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Sistema de Orçamentos
          </Typography>
        </Toolbar>
      </AppBarStyled>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: 'MediumBlue',
            color: '#fff',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <div>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose} sx={{ color: '#fff' }}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider sx={{ backgroundColor: '#fff' }} />
          <List>
            <ListItem button component={Link} to="/" sx={{ '&:hover': { backgroundColor: '#333' } }}>
              <ListItemIcon sx={{ color: '#fff' }}>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" sx={{ color: '#fff' }} />
            </ListItem>
            <ListItem button component={Link} to="/orcamentos" sx={{ '&:hover': { backgroundColor: '#333' } }}>
              <ListItemIcon sx={{ color: '#fff' }}>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="Orçamentos" sx={{ color: '#fff' }} />
            </ListItem>
            <ListItem button component={Link} to="/aprovados" sx={{ '&:hover': { backgroundColor: '#333' } }}>
              <ListItemIcon sx={{ color: '#fff' }}>
                <CheckCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Aprovados" sx={{ color: '#fff' }} />
            </ListItem>
            <ListItem button component={Link} to="/pendentes" sx={{ '&:hover': { backgroundColor: '#333' } }}>
              <ListItemIcon sx={{ color: '#fff' }}>
                <PendingActionsIcon />
              </ListItemIcon>
              <ListItemText primary="Pendentes" sx={{ color: '#fff' }} />
            </ListItem>
            <ListItem button component={Link} to="/relatorios" sx={{ '&:hover': { backgroundColor: '#333' } }}>
              <ListItemIcon sx={{ color: '#fff' }}>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText primary="Relatórios" sx={{ color: '#fff' }} />
            </ListItem>
          </List>
        </div>

        {/* Botão de Logout no rodapé */}
        <Box sx={{ p: 2 }}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            startIcon={<LogoutIcon />}
            onClick={handleLogout}
            sx={{
              backgroundColor: 'red',
              color: '#fff',
              '&:hover': {
                backgroundColor: 'darkred',
              },
            }}
          >
            Logout
          </Button>
        </Box>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {/* Cards */} 
        {children}

        {/* Logos das Marcas */}
        <Box sx={{ mt: 30 }}>
          <Grid container justifyContent="center" spacing={5}>
            {/* Logo Volkswagen */}
            <Grid item>
              <img src={VWLogo} alt="Volkswagen" style={{ width: '100px', height: 'auto' }} />
            </Grid>
            {/* Logo Chevrolet */}
            <Grid item>
              <img src={ChevroletLogo} alt="Chevrolet" style={{ width: '100px', height: 'auto' }} />
            </Grid>
            {/* Logo Mercedes-Benz */}
            <Grid item>
              <img src={MercedesLogo} alt="Mercedes-Benz" style={{ width: '100px', height: 'auto' }} />
            </Grid>
          </Grid>
        </Box>
      </Main>
    </Box>
  );
}
