import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid, Card, CardContent, Typography, Button, Snackbar, Alert, Modal } from '@mui/material';
import { aprovarOrcamento, getOrcamentosPendentes } from '../services/apiService';
import dayjs from 'dayjs';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { AuthContext } from '../AuthContext'; // Importar o contexto de autenticação

export default function Pendentes() {
  const { role } = useContext(AuthContext); // Obter o papel do usuário logado
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [filteredOrcamentos, setFilteredOrcamentos] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFile, setSelectedFile] = useState(null); 
  const [openModal, setOpenModal] = useState(false);  
  const [fileType, setFileType] = useState("");
  const [openDetailModal, setOpenDetailModal] = useState(false); 
  const [selectedOrcamento, setSelectedOrcamento] = useState(null); 

  useEffect(() => {
    const fetchOrcamentosPendentes = async () => {
      try {
        const data = await getOrcamentosPendentes();
        
        // Filtro para garantir que orçamentos aprovados por ambas as partes e reprovados não sejam exibidos
        const pendentes = data.filter(orcamento =>
          !orcamento.reprovado &&  // Excluir orçamentos reprovados
          !(orcamento.aprovacao_diretoria && orcamento.aprovacao_gerencia) // Excluir orçamentos aprovados por ambas as partes
        );
  
        setFilteredOrcamentos(pendentes);
      } catch (error) {
        console.error("Erro ao buscar orçamentos pendentes", error);
      }
    };
    fetchOrcamentosPendentes();
  }, []);

  const handleOpenFileModal = (fileName) => {
    setSelectedFile(null);
    setFileType("");

    const fileUrl = fileName;
    console.log("URL do arquivo:", fileUrl); // Verifique se a URL gerada está correta
  
    fetch(fileUrl, { method: 'HEAD' })
      .then(response => {
        if (response.ok) {
          setSelectedFile(fileUrl);
  
          if (fileUrl.endsWith('.pdf')) {
            setFileType('pdf');
          } else if (fileUrl.endsWith('.jpg') || fileUrl.endsWith('.jpeg') || fileUrl.endsWith('.png')) {
            setFileType('image');
          } else {
            setFileType('other');
          }
          
          setOpenModal(true);
        } else {
          console.error("Arquivo não encontrado:", fileUrl);
          setError(true);
        }
      })
      .catch(error => {
        console.error("Erro ao verificar a existência do arquivo:", error);
        setError(true);
      });
  };

  // Função para fechar o modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedFile(null);
    setFileType("");
  };

  const handleOpenDetailModal = (orcamento) => {
    setSelectedOrcamento(orcamento);
    setOpenDetailModal(true);
  };

  const handleCloseDetailModal = () => {
    setOpenDetailModal(false);
    setSelectedOrcamento(null);
  };

  // Aprovação pela diretoria
  const handleAprovarDiretoria = async (orcamento) => {
    try {
      const dataAprovacao = dayjs().format('YYYY-MM-DD');

      if (!orcamento.numero) {
        console.error("Número do orçamento inválido:", orcamento.numero);
        setError(true);
        return;
      }

      await aprovarOrcamento(String(orcamento.numero), {
        aprovacao_diretoria: true,
        data_aprovacao_diretoria: dataAprovacao,
      });

      setFilteredOrcamentos((prev) =>
        prev.map((orc) =>
          orc.numero === orcamento.numero
            ? { ...orc, aprovacao_diretoria: true, data_aprovacao_diretoria: dataAprovacao }
            : orc
        )
      );

      // Verifica se também foi aprovado pela gerência, se sim, remove da lista
      setFilteredOrcamentos((prev) =>
        prev.filter((orc) => !(orc.numero === orcamento.numero && orc.aprovacao_diretoria && orc.aprovacao_gerencia))
      );

      setSuccess(true);
    } catch (error) {
      console.error("Erro ao aprovar orçamento pela diretoria:", error.response || error.message || error);
      setError(true);
    }
  };

  // Aprovação pela gerência
  const handleAprovarGerencia = async (orcamento) => {
    try {
      const dataAprovacao = dayjs().format('YYYY-MM-DD');

      if (!orcamento.numero) {
        console.error("Número do orçamento inválido:", orcamento.numero);
        setError(true);
        return;
      }

      await aprovarOrcamento(String(orcamento.numero), {
        aprovacao_gerencia: true,
        data_aprovacao_gerencia: dataAprovacao,
      });

      setFilteredOrcamentos((prev) =>
        prev.map((orc) =>
          orc.numero === orcamento.numero
            ? { ...orc, aprovacao_gerencia: true, data_aprovacao_gerencia: dataAprovacao }
            : orc
        )
      );

      // Verifica se também foi aprovado pela diretoria, se sim, remove da lista
      setFilteredOrcamentos((prev) =>
        prev.filter((orc) => !(orc.numero === orcamento.numero && orc.aprovacao_diretoria && orc.aprovacao_gerencia))
      );

      setSuccess(true);
    } catch (error) {
      console.error("Erro ao aprovar orçamento pela gerência:", error.response || error.message || error);
      setError(true);
    }
  };

  const handleReprovarOrcamento = async (orcamento) => {
    try {
      if (!orcamento.numero) {
        console.error("Número do orçamento inválido:", orcamento.numero);
        setError(true);
        return;
      }

      // Atualiza o orçamento para marcar como reprovado
      await aprovarOrcamento(String(orcamento.numero), {
        reprovado: true,
      });

      // Remove o orçamento reprovado da lista de pendentes
      setFilteredOrcamentos((prev) =>
        prev.filter((orc) => orc.numero !== orcamento.numero)
      );

      setSuccess(true);  // Mostra uma mensagem de sucesso
    } catch (error) {
      console.error("Erro ao reprovar orçamento:", error.response || error.message || error);
      setError(true);  // Mostra uma mensagem de erro
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    const filtered = filteredOrcamentos.filter((orcamento) =>
      orcamento.titulo.toLowerCase().includes(e.target.value.toLowerCase()) ||
      orcamento.solicitante.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredOrcamentos(filtered);
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        Aprovação de Orçamentos Pendentes
      </Typography>

      <Box mb={3}>
        <input
          type="text"
          placeholder="Buscar por título ou solicitante"
          value={searchQuery}
          onChange={handleSearch}
          style={{ width: '100%', padding: '10px', fontSize: '16px', marginBottom: '20px' }}
        />
      </Box>

      <Grid container spacing={3}>
        {filteredOrcamentos.map((orcamento) => (
          <Grid item xs={12} sm={6} md={4} key={orcamento.numero}>
            <Card>
              <CardContent>
                <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem' }}>
                  {orcamento.titulo}
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
                  Solicitante: <span style={{ fontWeight: 'normal' }}>{orcamento.solicitante}</span>
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
                  Valor: <span style={{ fontWeight: 'normal' }}>
                    {orcamento.valor && !isNaN(orcamento.valor) ? `R$ ${parseFloat(orcamento.valor).toFixed(2)}` : 'Valor inválido'}
                  </span>
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  Data de Emissão: <span style={{ fontWeight: 'normal' }}>
                    {orcamento.data_emissao ? dayjs(orcamento.data_emissao).format('DD/MM/YYYY') : 'Data não disponível'}
                  </span>
                </Typography>

                {/* Exibição dos botões de acordo com o papel do usuário */}
                <Box mt={2} display="flex" flexDirection="column" gap={2}>
                  {/* Aprovação pela Diretoria */}
                  {role === 'G' && !orcamento.aprovacao_diretoria && (
                    <Button variant="contained" color="primary" fullWidth onClick={() => handleAprovarDiretoria(orcamento)}>
                      Aprovar Diretoria
                    </Button>
                  )}
                  {orcamento.aprovacao_diretoria && (
                    <Typography variant="body2" color="green">
                      Aprovado pela Diretoria em: {orcamento.data_aprovacao_diretoria}
                    </Typography>
                  )}

                  {/* Aprovação pela Gerência */}
                  {['S', 'R', 'B'].includes(role) && !orcamento.aprovacao_gerencia && (
                    <Button variant="contained" color="secondary" fullWidth onClick={() => handleAprovarGerencia(orcamento)}>
                      Aprovar Gerência
                    </Button>
                  )}
                  {orcamento.aprovacao_gerencia && (
                    <Typography variant="body2" color="green">
                      Aprovado pela Gerência em: {orcamento.data_aprovacao_gerencia}
                    </Typography>
                  )}

                  {/* Botão de Detalhamento (disponível para todos) */}
                  <Button variant="contained" color="primary" fullWidth onClick={() => handleOpenDetailModal(orcamento)}>
                    Detalhamento
                  </Button>

                  {/* Botão de Reprovar (apenas para Diretoria e Gerência) */}
                  {['G', 'S', 'R', 'B'].includes(role) && (
                    <Button variant="contained" color="error" fullWidth onClick={() => handleReprovarOrcamento(orcamento)}>
                      Reprovar
                    </Button>
                  )}
                </Box>

                {/* Exibição dos anexos */}
                <Box mt={2} display="flex" justifyContent="center" gap={2}>
                  {orcamento.anexo && (
                    <Button variant="outlined" onClick={() => handleOpenFileModal(orcamento.anexo)}>
                      <VisibilityIcon /> Ver Anexo 1
                    </Button>
                  )}
                  {orcamento.anexo1 && (
                    <Button variant="outlined" onClick={() => handleOpenFileModal(orcamento.anexo1)}>
                      <VisibilityIcon /> Ver Anexo 2
                    </Button>
                  )}
                  {orcamento.anexo2 && (
                    <Button variant="outlined" onClick={() => handleOpenFileModal(orcamento.anexo2)}>
                      <VisibilityIcon /> Ver Anexo 3
                    </Button>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Modal para visualização do arquivo */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '80%', bgcolor: 'background.paper', p: 4 }}>
          {selectedFile ? (
            fileType === 'image' ? (
              <img src={selectedFile} alt="Imagem Anexo" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
            ) : fileType === 'pdf' ? (
              <iframe
                src={selectedFile}
                title="Visualizador de PDF"
                width="100%"
                height="600px"
                style={{ border: 'none' }}
              ></iframe>
            ) : (
              <Typography variant="body1">
                Tipo de arquivo não suportado para visualização. <a href={selectedFile} target="_blank" rel="noopener noreferrer">Baixe o arquivo aqui</a>.
              </Typography>
            )
          ) : (
            <Typography variant="body1">Nenhum arquivo selecionado.</Typography>
          )}
        </Box>
      </Modal>

      {/* Modal de Detalhamento */}
      <Modal open={openDetailModal} onClose={handleCloseDetailModal}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '60%', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          {selectedOrcamento && (
            <div>
              <Typography variant="h5" gutterBottom>
                Detalhes do Orçamento: {selectedOrcamento.titulo}
              </Typography>
              <Typography variant="body1">
                <strong>Solicitante:</strong> {selectedOrcamento.solicitante}
              </Typography>
              <Typography variant="body1">
                <strong>Valor:</strong> {selectedOrcamento.valor ? `R$ ${parseFloat(selectedOrcamento.valor).toFixed(2)}` : 'Valor inválido'}
              </Typography>
              <Typography variant="body1">
                <strong>Data de Emissão:</strong> {selectedOrcamento.data_emissao ? dayjs(selectedOrcamento.data_emissao).format('DD/MM/YYYY') : 'Data não disponível'}
              </Typography>
              <Typography variant="body1">
                <strong>Descrição:</strong> {selectedOrcamento.descricao || 'Sem descrição'}
              </Typography>
            </div>
          )}
        </Box>
      </Modal>

      <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
        <Alert onClose={() => setSuccess(false)} severity="success">
          Operação realizada com sucesso!
        </Alert>
      </Snackbar>

      <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)}>
        <Alert onClose={() => setError(false)} severity="error">
          Erro ao processar operação.
        </Alert>
      </Snackbar>
    </Box>
  );
}
