import * as React from 'react';
import { Box, Grid, Card, CardContent, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import AssessmentIcon from '@mui/icons-material/Assessment'; // Ícone para Relatórios
import { Link } from 'react-router-dom'; // Para redirecionamento

// Estilização para os cards (com sombra e efeito hover)
const StyledCard = styled(Card)({
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
  },
});

// Estilização para os ícones
const IconContainer = styled(IconButton)({
  backgroundColor: '#f0f0f0',
  borderRadius: '50%',
  padding: '20px',
  '&:hover': {
    backgroundColor: '#e0e0e0',
  },
  '& svg': {
    color: '#4caf50', // Altere a cor conforme necessário
    fontSize: '3rem',
  },
});

export default function Dashboard() {
  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', fontSize: '2.5rem', color: '#1a73e8' }}>
        Início
      </Typography>

      <Grid container spacing={4}>
        {/* Card Orçamentos */}
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <CardContent sx={{ textAlign: 'center' }}>
              <IconContainer component={Link} to="/orcamentos">
                <DescriptionIcon />
              </IconContainer>
              <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mt: 2 }}>
                Orçamentos
              </Typography>
              <Typography variant="body2" color="textSecondary">
               Criar Novos Orçamentos .
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Card Aprovados */}
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <CardContent sx={{ textAlign: 'center' }}>
              <IconContainer component={Link} to="/aprovados">
                <CheckCircleIcon />
              </IconContainer>
              <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mt: 2 }}>
                Aprovados
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Orçamentos aprovados.
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Card Pendentes */}
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <CardContent sx={{ textAlign: 'center' }}>
              <IconContainer component={Link} to="/pendentes">
                <PendingActionsIcon />
              </IconContainer>
              <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mt: 2 }}>
                Pendentes
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Orçamentos pendentes.
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Card Relatórios */}
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <CardContent sx={{ textAlign: 'center' }}>
              <IconContainer component={Link} to="/relatorios">
                <AssessmentIcon />
              </IconContainer>
              <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mt: 2 }}>
                Relatórios
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Relatórios disponíveis.
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </Box>
  );
}
