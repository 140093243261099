import axios from 'axios';

const API_URL = 'https://nuclearx.com.br/api_despesa/bi_orcamento/';

// Função para obter os orçamentos pendentes
export const getOrcamentosPendentes = async () => {
  try {
    const response = await axios.get(API_URL);
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar orçamentos pendentes", error.response || error.message);
    throw error;
  }
};

// Função para adicionar um novo orçamento
export const addOrcamento = async (orcamento) => {
  try {
    const formData = new FormData();

    // Adicionando campos ao FormData
    formData.append('numero', orcamento.numero);
    formData.append('cod_empresa', orcamento.cod_empresa.toString());
    formData.append('solicitante', orcamento.solicitante);
    formData.append('titulo', orcamento.titulo);
    formData.append('descricao', orcamento.descricao);
    formData.append('quantidade', orcamento.quantidade.toString());
    formData.append('valor', orcamento.valor);

    // Ajuste: Enviar valores booleanos diretamente
    formData.append('aprovacao_diretoria', orcamento.aprovacao_diretoria);
    formData.append('aprovacao_gerencia', orcamento.aprovacao_gerencia);
    formData.append('reprovado', orcamento.reprovado);

    formData.append('data_emissao', orcamento.data_emissao);

    // Adicionando anexos, se disponíveis
    if (orcamento.anexo) formData.append('anexo', orcamento.anexo);
    if (orcamento.anexo1) formData.append('anexo1', orcamento.anexo1);
    if (orcamento.anexo2) formData.append('anexo2', orcamento.anexo2);

    const response = await axios.post(API_URL, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao adicionar orçamento:', error.response || error.message);
    throw error;
  }
};

// Função para aprovar orçamento (diretoria ou gerência)
export const aprovarOrcamento = async (numero, dadosAprovacao) => {
  try {
    // Certifique-se de que o 'numero' está sendo passado corretamente
    const response = await axios.patch(`${API_URL}?numero=${String(numero)}`, dadosAprovacao, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao aprovar orçamento", error.response || error.message);
    throw error;
  }
};

// Função para obter os orçamentos aprovados
export const getOrcamentosAprovados = async () => {
  try {
    const response = await axios.get(`${API_URL}?aprovado=true`);
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar orçamentos aprovados", error.response || error.message);
    throw error;
  }
};

// Função para reprovar orçamento (deleção)
export const reprovarOrcamento = async (numero) => {
  try {
    // Supondo que o backend espera o ID do orçamento na URL e aceita PATCH para atualização parcial
    const response = await axios.patch(`${API_URL}/orcamentos/${numero}`, { reprovado: true });

    return response.data;
  } catch (error) {
    console.error("Erro ao reprovar orçamento", error.response || error.message);
    throw error;
  }
};

// Função para fazer upload de anexo
export const uploadAnexo = async (numero, formData) => {
  try {
    const response = await axios.post(`${API_URL}${numero}/upload_anexo/`, formData);
    return response.data;
  } catch (error) {
    console.error("Erro ao fazer upload do anexo", error.response || error.message);
    throw error;
  }
};

// Função para gerar relatório
export const gerarRelatorio = async () => {
  try {
    const response = await axios.get(`${API_URL}gerar_relatorio/`, {
      responseType: 'blob', // Importante para downloads de arquivos
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao gerar relatório", error.response || error.message);
    throw error;
  }
};

// Função para fazer upload do anexo_nota
export const uploadAnexoNota = async (numero, anexoNotaFile) => {
  try {
    const formData = new FormData();
    formData.append('anexo_nota', anexoNotaFile); // Adiciona o arquivo ao FormData

    // Faz a requisição para o endpoint correto com o número do orçamento
    const response = await axios.patch(`${API_URL}?numero=${numero}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // Verifica se a API retorna a URL do arquivo
    const fileUrl = response.data.anexo_nota;

    return { anexo_nota: fileUrl };
  } catch (error) {
    console.error('Erro ao fazer upload do anexo_nota:', error.response || error.message);
    throw error;
  }
};

export const updateOrcamento = async (orcamento) => {
  try {
    const formData = new FormData();

    // Adicionando campos ao FormData
    formData.append('numero', orcamento.numero); // ID único do orçamento
    formData.append('cod_empresa', orcamento.cod_empresa.toString());
    formData.append('solicitante', orcamento.solicitante);
    formData.append('titulo', orcamento.titulo);
    formData.append('descricao', orcamento.descricao);
    formData.append('quantidade', orcamento.quantidade.toString());
    formData.append('valor', orcamento.valor);

    // Atualizando os campos de aprovação
    formData.append('aprovacao_diretoria', !!orcamento.aprovacao_diretoria);
    formData.append('aprovacao_gerencia', !!orcamento.aprovacao_gerencia);
    formData.append('reprovado', orcamento.reprovado);

    formData.append('data_emissao', orcamento.data_emissao);

    // Atualizando anexos, se disponíveis
    if (orcamento.anexo) formData.append('anexo', orcamento.anexo);
    if (orcamento.anexo1) formData.append('anexo1', orcamento.anexo1);
    if (orcamento.anexo2) formData.append('anexo2', orcamento.anexo2);
    if (orcamento.anexo_nota) formData.append('anexo_nota', orcamento.anexo_nota);

    // Fazendo a requisição PATCH para atualizar o orçamento existente
    const response = await axios.patch(`${API_URL}?numero=${orcamento.numero}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao atualizar orçamento:', error.response || error.message);
    throw error;
  }
};


const API_LOGIN_URL = 'https://nuclearx.com.br/api_despesa/bi_login/';

// Função para autenticação do usuário
export const loginUser = async (login, senha) => {
  try {
    const response = await axios.get(`${API_LOGIN_URL}?login=${login}&senha=${senha}`);
    if (response.status === 200 && response.data.length > 0) {
      const userData = response.data[0];
      return userData;
    } else {
      throw new Error('Credenciais inválidas');
    }
  } catch (error) {
    console.error('Erro ao fazer login:', error);
    throw error;
  }
};