// src/components/PrivateRoute.js
import React, { useContext } from 'react';
import { AuthContext } from '../AuthContext';
import { Navigate } from 'react-router-dom';

function PrivateRoute({ children }) {
  const { usuario } = useContext(AuthContext);

  if (!usuario) {
    // Usuário não está autenticado
    return <Navigate to="/login" />;
  }

  // Usuário está autenticado
  return children;
}

export default PrivateRoute;
