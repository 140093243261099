import React, { useState, useContext, useEffect } from 'react';
import { TextField, Button, Grid, Container, Typography, Box, Paper, MenuItem, Select, InputLabel, FormControl, Snackbar, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';  // Importar o contexto da autenticação
import { addOrcamento } from '../services/apiService'; // O caminho correto deve incluir ../ para subir um nível

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(5),
  padding: theme.spacing(4),
  backgroundColor: '#f9f9f9',
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
  backgroundColor: '#fff',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.05)',
  borderRadius: theme.shape.borderRadius,
}));

const OrcamentoForm = () => {
  const { usuario, orcamentoPermissoes } = useContext(AuthContext);  // Obtenha o usuário logado e as permissões do orçamento do contexto
  const [titulo, setTitulo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [solicitante, setSolicitante] = useState(''); // Vamos utilizar esse estado para armazenar o nome do usuário
  const [quantidade, setQuantidade] = useState(1);
  const [valor, setValor] = useState('');
  const [documentos, setDocumentos] = useState({ doc1: null, doc2: null, doc3: null });
  const [empresa, setEmpresa] = useState('');
  const [empresaOptions, setEmpresaOptions] = useState([]);  // Adicionar estado para as opções de empresas
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  // Função para definir as opções de empresa com base na função do usuário
  useEffect(() => {
    const getEmpresaOptions = () => {
      const todasEmpresas = [
        { value: '2', label: 'BLU STAR' },
        { value: '29', label: 'BLU STAR - BRUSQUE' },
        { value: '4', label: 'SANTA CLARA 4 - MATRIZ' },
        { value: '5', label: 'SANTA CLARA 5 - FILIAL' },
        { value: '13', label: 'SANTA CLARA 2 - SETEMBRO' },
        { value: '17', label: 'SANTA CLARA 17 - GASPAR' },
        { value: '9', label: 'RF ICARA' },
        { value: '11', label: 'RF PALHOCA' },
        { value: '14', label: 'RF TIJUCAS' },
        { value: '19', label: 'RF SUL TUBARAO' },
      ];

      // Filtrar as empresas com base nas permissões de orçamento do usuário
      if (orcamentoPermissoes && orcamentoPermissoes.length > 0) {
        return todasEmpresas.filter((empresa) => orcamentoPermissoes.includes(empresa.value));
      } else {
        return todasEmpresas;  // Caso o usuário tenha permissão para ver todas as empresas
      }
    };

    if (orcamentoPermissoes && usuario) {
      setEmpresaOptions(getEmpresaOptions());
    }

    // Preencher automaticamente o campo "Solicitante" com o nome do usuário logado
    if (usuario && usuario.nome) {
      setSolicitante(usuario.nome); // Define o solicitante com o nome do usuário logado
    }
  }, [orcamentoPermissoes, usuario]);  // Adiciona `usuario` à lista de dependências

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validação de campos obrigatórios
    if (!empresa || !titulo || !solicitante || !quantidade || !valor) {
      alert('Todos os campos obrigatórios devem ser preenchidos!');
      return;
    }

    const numeroOrcamento = `ORC-${new Date().toISOString().replace(/[^\d]/g, '').slice(0, 14)}`;

    const orcamento = {
      numero: numeroOrcamento,
      cod_empresa: parseInt(empresa, 10),
      solicitante,  // Utiliza o nome do usuário logado
      titulo,
      descricao,
      quantidade: parseInt(quantidade, 10),
      valor: parseFloat(valor).toFixed(2),
      aprovacao_diretoria: false,
      aprovacao_gerencia: false,
      reprovado: false,
      anexo: documentos.doc1 || null,
      anexo1: documentos.doc2 || null,
      anexo2: documentos.doc3 || null,
      data_emissao: new Date().toISOString().split('T')[0],
      data_aprovacao_gerencia: null,
      data_aprovacao_diretoria: null
    };

    try {
      await addOrcamento(orcamento);
      setSuccess(true);
      setTitulo('');
      setDescricao('');
      setQuantidade(1);
      setValor('');
      setEmpresa('');
      setDocumentos({ doc1: null, doc2: null, doc3: null });

      setTimeout(() => navigate('/Pendentes'), 2000);
    } catch (error) {
      console.error('Erro ao adicionar orçamento:', error);
      setError(true);
    }
  };

  const handleDocumentUpload = (event, field) => {
    setDocumentos({
      ...documentos,
      [field]: event.target.files[0],
    });
  };

  return (
    <StyledContainer maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Criar Orçamento
      </Typography>
      <StyledPaper>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Título"
                fullWidth
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Solicitante"
                fullWidth
                value={solicitante} // Preenchido automaticamente com o nome do usuário logado
                disabled // Desabilita o campo para que o usuário não possa editar
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="empresa-select-label">Selecione a Empresa</InputLabel>
                <Select
                  labelId="empresa-select-label"
                  value={empresa}
                  onChange={(e) => setEmpresa(e.target.value)}
                  required
                >
                  {empresaOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Descrição"
                fullWidth
                multiline
                rows={4}
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="number"
                label="Quantidade"
                fullWidth
                value={quantidade}
                onChange={(e) => setQuantidade(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="number"
                label="Valor"
                fullWidth
                value={valor}
                onChange={(e) => setValor(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Anexar Documentos
              </Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <StyledButton variant="contained" component="label">
                  Anexar Documento 1
                  <input type="file" hidden onChange={(e) => handleDocumentUpload(e, 'doc1')} accept=".pdf, image/*" />
                </StyledButton>
                <StyledButton variant="contained" component="label">
                  Anexar Documento 2
                  <input type="file" hidden onChange={(e) => handleDocumentUpload(e, 'doc2')} accept=".pdf, image/*" />
                </StyledButton>
                <StyledButton variant="contained" component="label">
                  Anexar Documento 3
                  <input type="file" hidden onChange={(e) => handleDocumentUpload(e, 'doc3')} accept=".pdf, image/*" />
                </StyledButton>
              </Box>
              <Box mt={2}>
                {documentos.doc1 && <Typography>Documento 1: {documentos.doc1.name}</Typography>}
                {documentos.doc2 && <Typography>Documento 2: {documentos.doc2.name}</Typography>}
                {documentos.doc3 && <Typography>Documento 3: {documentos.doc3.name}</Typography>}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <StyledButton variant="contained" color="primary" type="submit" fullWidth>
                Criar Orçamento
              </StyledButton>
            </Grid>
          </Grid>
        </form>
      </StyledPaper>

      <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
        <Alert onClose={() => setSuccess(false)} severity="success">
          Orçamento criado com sucesso!
        </Alert>
      </Snackbar>

      <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)}>
        <Alert onClose={() => setError(false)} severity="error">
          Erro ao criar orçamento.
        </Alert>
      </Snackbar>
    </StyledContainer>
  );
};

export default OrcamentoForm;
