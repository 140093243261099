import React, { createContext, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [usuario, setUsuario] = useState(null); // Informações do usuário logado
  const [role, setRole] = useState(null); // Função (permissão) do usuário (G, B, R, S)
  const [orcamentoPermissoes, setOrcamentoPermissoes] = useState([]); // Lista de códigos das empresas permitidas

  return (
    <AuthContext.Provider
      value={{
        usuario,
        setUsuario,
        role,
        setRole,
        orcamentoPermissoes,
        setOrcamentoPermissoes,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
