import React, { useState, useEffect, useContext } from 'react';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Typography,
  IconButton,
  Collapse,
  Box,
  Modal,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { styled } from '@mui/system';
import { getOrcamentosAprovados } from '../services/apiService';
import { AuthContext } from '../AuthContext';  // Importa o contexto de autenticação

const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: 700,
  width: '100%',
  backgroundColor: '#f7f9fc',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1rem',
  color: '#333',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#1a73e8',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#1765cf',
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  padding: '16px',
  borderRadius: '8px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 8px 25px rgba(0, 0, 0, 0.15)',
  },
}));

const RelatorioOrcamentos = () => {
  const { orcamentoPermissoes } = useContext(AuthContext);  // Pega as permissões do contexto
  const [filtroSolicitante, setFiltroSolicitante] = useState('');
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [orcamentos, setOrcamentos] = useState([]);
  const [filteredOrcamentos, setFilteredOrcamentos] = useState([]);
  const [openRows, setOpenRows] = useState({});
  const [empresaFiltro, setEmpresaFiltro] = useState(''); // Novo filtro para empresa
  const [openModal, setOpenModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  // Lista de empresas para exibir no relatório
  const todasEmpresas = [
    { value: '2', label: 'BLU STAR' },
    { value: '29', label: 'BLU STAR - BRUSQUE' },
    { value: '4', label: 'SANTA CLARA 4 - MATRIZ' },
    { value: '5', label: 'SANTA CLARA 5 - FILIAL' },
    { value: '13', label: 'SANTA CLARA 2 - SETEMBRO' },
    { value: '17', label: 'SANTA CLARA 17 - GASPAR' },
    { value: '9', label: 'RF ICARA' },
    { value: '11', label: 'RF PALHOCA' },
    { value: '14', label: 'RF TIJUCAS' },
    { value: '19', label: 'RF SUL TUBARAO' },
  ];

  // Função para carregar orçamentos
  useEffect(() => {
    const carregarOrcamentosAprovados = async () => {
      try {
        const data = await getOrcamentosAprovados();
        
        // Aplicar filtro para exibir apenas as empresas permitidas para o usuário
        const orcamentosFiltradosPorPermissao = data.filter(orcamento =>
          orcamentoPermissoes.includes(orcamento.cod_empresa.toString())
        );
        
        setOrcamentos(orcamentosFiltradosPorPermissao);
        setFilteredOrcamentos(orcamentosFiltradosPorPermissao);  // Inicialmente, todos os orçamentos são exibidos
      } catch (error) {
        console.error('Erro ao carregar orçamentos aprovados:', error);
      }
    };
    carregarOrcamentosAprovados();
  }, [orcamentoPermissoes]);

  const getEmpresaLabel = (codEmpresa) => {
    const empresa = todasEmpresas.find(emp => emp.value === codEmpresa.toString());
    return empresa ? empresa.label : 'Empresa não identificada';
  };

  const handleCarregarOrcamentos = () => {
    const orcamentosFiltrados = orcamentos.filter((orcamento) => {
      const solicitanteMatch = filtroSolicitante
        ? orcamento.solicitante && orcamento.solicitante.toLowerCase().includes(filtroSolicitante.toLowerCase())
        : true;

      const dataEmissao = new Date(orcamento.data_emissao);

      let dataInicioMatch = true;
      let dataFimMatch = true;

      if (dataInicio) {
        const dataInicioObj = new Date(dataInicio);
        dataInicioMatch = dataEmissao >= dataInicioObj;
      }

      if (dataFim) {
        const dataFimObj = new Date(dataFim);
        dataFimMatch = dataEmissao <= dataFimObj;
      }

      const filtroEmpresaSelecionada = empresaFiltro ? orcamento.cod_empresa.toString() === empresaFiltro : true;

      return solicitanteMatch && dataInicioMatch && dataFimMatch && filtroEmpresaSelecionada;
    });

    setFilteredOrcamentos(orcamentosFiltrados);
  };

  const toggleRow = (index) => {
    setOpenRows((prevState) => ({ ...prevState, [index]: !prevState[index] }));
  };

  const handleOpenFileModal = (fileUrl) => {
    setSelectedFile(fileUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedFile(null);
  };

  return (
    <Container sx={{ mt: 4 }} maxWidth={false}>
    <Typography
      variant="h4"
      textAlign="center"
      gutterBottom
      sx={{ fontWeight: 'bold', color: '#1a73e8', mb: 4 }}
    >
      Relatório de Orçamentos Aprovados
    </Typography>

    {/* Container flex para organizar o filtro e a tabela */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between', // Mantém os filtros e a tabela separados
        alignItems: 'flex-start', // Alinha o topo de ambos
        gap: 4, // Espaçamento entre o filtro e a tabela
      }}
    >
      {/* Caixa dos filtros, com largura fixa */}
      <StyledBox sx={{ width: '300px' }}>
        <TextField
          label="Selecione o Solicitante"
          value={filtroSolicitante}
          onChange={(e) => setFiltroSolicitante(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Data Início"
          type="date"
          value={dataInicio}
          onChange={(e) => setDataInicio(e.target.value)}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="Data Fim"
          type="date"
          value={dataFim}
          onChange={(e) => setDataFim(e.target.value)}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        {/* Filtro de Empresa */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Filtrar por Empresa</InputLabel>
          <Select
            value={empresaFiltro}
            onChange={(e) => setEmpresaFiltro(e.target.value)}
          >
            <MenuItem value="">Todas as Empresas</MenuItem>
            {orcamentoPermissoes.map((empresa) => (
              <MenuItem key={empresa} value={empresa}>
                {getEmpresaLabel(empresa)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <StyledButton
          variant="contained"
          onClick={handleCarregarOrcamentos}
          sx={{ mt: 2 }}
        >
          Carregar Orçamentos
        </StyledButton>
      </StyledBox>

      {/* Container da tabela, que ocupa o restante da tela */}
      <Box sx={{ flexGrow: 1, width: '100%' }}>
        <TableContainer sx={{ width: '100%' }}>
          <StyledTable>
            <TableHead>
              <TableRow>
                <StyledTableCell />
                <StyledTableCell>Solicitante</StyledTableCell>
                <StyledTableCell>Título</StyledTableCell>
                <StyledTableCell>Valor</StyledTableCell>
                <StyledTableCell>Data de Emissão</StyledTableCell>
                <StyledTableCell>Empresa</StyledTableCell>
                <StyledTableCell>Aprovação Diretoria</StyledTableCell>
                <StyledTableCell>Aprovação Gerência</StyledTableCell>
                <StyledTableCell>Nota Fiscal</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredOrcamentos.map((orcamento, index) => (
                <React.Fragment key={orcamento.id}>
                  <TableRow>
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => toggleRow(index)}
                      >
                        {openRows[index] ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell>{orcamento.solicitante}</TableCell>
                    <TableCell>{orcamento.titulo}</TableCell>
                    <TableCell>R$ {parseFloat(orcamento.valor).toFixed(2)}</TableCell>
                    <TableCell>{orcamento.data_emissao || 'Data não disponível'}</TableCell>
                    <TableCell>{getEmpresaLabel(orcamento.cod_empresa)}</TableCell>
                    <StyledTableCell>
                      {orcamento.aprovacao_diretoria ? 'Sim' : 'Não'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {orcamento.aprovacao_gerencia ? 'Sim' : 'Não'}
                    </StyledTableCell>
                    <TableCell>
                      {orcamento.anexo_nota ? (
                        <IconButton onClick={() => handleOpenFileModal(orcamento.anexo_nota)}>
                          <VisibilityIcon />
                        </IconButton>
                      ) : (
                        'Nenhuma nota fiscal'
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                      <Collapse in={openRows[index]} timeout="auto" unmountOnExit>
                        <Box margin={2}>
                          <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                              <Typography variant="h6" gutterBottom component="div" sx={{ fontWeight: 'bold', color: '#1a73e8' }}>
                                Detalhes do Orçamento
                              </Typography>
                              <Typography variant="body2">
                                <strong>Data Aprovação Diretoria:</strong> {orcamento.data_aprovacao_diretoria || 'Não informada'}
                              </Typography>
                              <Typography variant="body2">
                                <strong>Data Aprovação Gerência:</strong> {orcamento.data_aprovacao_gerencia || 'Não informada'}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1, color: '#1a73e8' }} gutterBottom component="div">
                                Descrição do Orçamento
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{
                                  whiteSpace: 'normal',
                                  maxHeight: '250px',
                                  overflowY: 'auto',
                                  paddingRight: '5px',
                                  wordWrap: 'break-word',
                                  maxWidth: '750px',
                                }}
                              >
                                {orcamento.descricao || 'Sem descrição'}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Box sx={{ mt: 4 }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2, color: '#1a73e8' }} gutterBottom component="div">
                              Anexos do Orçamento
                            </Typography>
                            <Grid container spacing={2}>
                              {orcamento.anexo && (
                                <Grid item>
                                  <IconButton onClick={() => handleOpenFileModal(orcamento.anexo)}>
                                    <VisibilityIcon />
                                  </IconButton>
                                </Grid>
                              )}
                              {orcamento.anexo1 && (
                                <Grid item>
                                  <IconButton onClick={() => handleOpenFileModal(orcamento.anexo1)}>
                                    <VisibilityIcon />
                                  </IconButton>
                                </Grid>
                              )}
                              {orcamento.anexo2 && (
                                <Grid item>
                                  <IconButton onClick={() => handleOpenFileModal(orcamento.anexo2)}>
                                    <VisibilityIcon />
                                  </IconButton>
                                </Grid>
                              )}
                              {!orcamento.anexo && !orcamento.anexo1 && !orcamento.anexo2 && (
                                <Typography>Nenhum anexo disponível</Typography>
                              )}
                            </Grid>
                          </Box>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </Box>
    </Box>

    <Modal open={openModal} onClose={handleCloseModal}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          height: '90%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        {selectedFile && (
          <>
            <Typography id="modal-title" variant="h6" component="h2">
              Visualizar Nota Fiscal
            </Typography>
            <iframe
              src={selectedFile}
              title="Visualizar Nota Fiscal"
              style={{ width: '100%', height: '80vh' }}
            />
          </>
        )}
      </Box>
    </Modal>
  </Container>

  );
};

export default RelatorioOrcamentos;
